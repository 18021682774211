import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/core-module/services/api.service";
import { isNullOrUndefined } from "util";

const customMinMaxValidator = (propsDetails, prop) => {
  return (control: FormControl) => {
    const propData = propsDetails[prop];
    const value = control.value;
    const min = propData.min;
    const max = propData.max;
    if (value < min || value > max) return { error: true };
    return null;
  };
};

const customCCTValidator = (
  formgroup: FormGroup,
  checkprop,
  condition,
  min,
  max,
  checkMin,
  checkMax
) => {
  return (control: FormControl) => {
    const checkControl = formgroup.get(checkprop);
    let controlValue = control.value;
    let checkValue = checkControl.value;
    let controlError = null;
    if (
      controlValue > max ||
      controlValue < min ||
      checkValue < checkMin ||
      checkValue > checkMax ||
      (condition == "lessthanequal" && controlValue > checkValue) ||
      (condition == "greaterthanequal" && controlValue < checkValue)
    ) {
      controlError = { error: true };
    }
    return controlError;
  };
};

@Component({
  selector: "app-flextune",
  templateUrl: "./flextune.component.html",
  styleUrls: ["./flextune.component.scss"],
})
export class FlextuneComponent extends FieldType implements OnInit {
  @Input() field;

  valueChangesSubs: { [key: string]: Subscription } = {};
  properties: any;
  quickConfigvalue: boolean;
  allDropDownItems = {};
  propertiesInfo = {};

  isIndoor: boolean = false;
  applicableProperties = [];
  flextuneFormGroup: FormGroup = new FormGroup({});

  constructor(private service: ApiService, private ref: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
      } else if (data === "standard") {
        this.quickConfigvalue = false;
      }
    });

    if (this.field) {
      this.properties = this.field.properties;
      this.applicableProperties = this.field.requiredProperties;
      if (this.applicableProperties.length == 13) {
        this.isIndoor = true;
      }
      this.applicableProperties.push("WarmAOC");
      this.applicableProperties
        .concat(["warmLightRatedLumen", "coolLightRatedLumen"])
        .forEach((prop) => {
          this.flextuneFormGroup.addControl(
            prop,
            new FormControl({
              value: null,
              disabled: ["warmLightRatedLumen", "coolLightRatedLumen"].includes(
                prop
              ),
            })
          );
        });
      this.allDropDownItems["LightOutputOverCct"] = [
        ...this.properties.LightOutputOverCct.enum,
      ];

      this.buildForm();

      this.flextuneFormGroup.valueChanges.subscribe((value) => {
        this.saveValueOnChange();
      });

      this.service.restoreFlextuneDefault.subscribe((res) => {
        this.restoreDefault();
      });
    }
  }

  saveValueOnChange() {
    const warmAocControl = this.flextuneFormGroup.get("WarmAOC");
    const disabled = warmAocControl.disabled;
    if (disabled) warmAocControl.enable({ emitEvent: false });
    this.setValueInLocal();
    this.service.setFlextuneDefaultColor(this.checkDefault());
    this.sendFlextuneData();
    this.service.sendFlextuneValidity(this.flextuneFormGroup.valid);
    if (disabled) warmAocControl.disable({ emitEvent: false });
  }

  preparePropValues(propName, initialValue) {
    const propData = this.properties[propName];
    if (!propData) return;
    if (!this.propertiesInfo[propName]) {
      this.propertiesInfo[propName] = {};
    }
    let info = this.propertiesInfo[propName];
    info["default"] = propData.default;
    info["value"] = initialValue;
  }

  preparePropertyConstraints(propName, options = {}) {
    const propData = this.properties[propName];
    if (!propData) return;
    if (!this.propertiesInfo[propName]) {
      this.propertiesInfo[propName] = {};
    }
    let info = this.propertiesInfo[propName];
    if (options["hasValueFromLocal"]) {
      for (let key in options) {
        if (key != "hasValueFromLocal" && key != "value") {
          info[key] = options[key];
        }
      }
      return;
    }
    let min = propData.minimum;
    let max = propData.maximum;
    let sliderMin = min;
    let sliderMax = max;
    if (propName == "WarmCct") {
      max = this.properties["CoolCct"].maximum;
      sliderMax = max;
    }
    if (propName == "CoolCct") {
      min = this.properties["WarmCct"].minimum;
      sliderMin = min;
    }

    if (
      propName == "CctCoolest" ||
      propName == "PowerOnCct" ||
      propName == "SystemFailureCct" ||
      propName == "CctWarmest"
    ) {
      const CoolCct = this.flextuneFormGroup.get("CoolCct");
      const WarmCct = this.flextuneFormGroup.get("WarmCct");
      const CctWarmest = this.flextuneFormGroup.get("CctWarmest");
      const CctCoolest = this.flextuneFormGroup.get("CctCoolest");
      sliderMin = WarmCct.valid
        ? this.propertiesInfo["WarmCct"].value
        : info.sliderMin;
      sliderMax = CoolCct.valid
        ? this.propertiesInfo["CoolCct"].value
        : info.sliderMax;

      if (propName == "PowerOnCct" || propName == "SystemFailureCct") {
        min = CctWarmest.valid
          ? this.propertiesInfo["CctWarmest"].value
          : info.min;
        max = CctCoolest.valid
          ? this.propertiesInfo["CctCoolest"].value
          : info.max;
      }
      if (propName == "CctCoolest") {
        max = sliderMax;
        min = CctWarmest.valid
          ? this.propertiesInfo["CctWarmest"].value
          : info.min;
      }
      if (propName == "CctWarmest") {
        max = CctCoolest.valid
          ? this.propertiesInfo["CctCoolest"].value
          : info.max;
        min = sliderMin;
      }
    }

    info["min"] = min;
    info["max"] = max;
    info["sliderMax"] = sliderMax;
    info["sliderMin"] = sliderMin;
    info["options"] = {
      floor: sliderMin,
      ceil: sliderMax,
      minLimit: min,
      maxLimit: max,
    };
  }

  addValidatorForProp(propName) {
    if (!this.propertiesInfo[propName]) return;
    const control = this.flextuneFormGroup.controls[propName];
    const max = this.propertiesInfo[propName].max;
    const min = this.propertiesInfo[propName].min;
    let validators = [
      customMinMaxValidator(this.propertiesInfo, propName),
      Validators.required,
    ];
    if (propName == "CoolCct" || propName == "WarmCct") {
      const checkProp = propName == "CoolCct" ? "WarmCct" : "CoolCct";
      const condition =
        propName == "CoolCct" ? "greaterthanequal" : "lessthanequal";
      validators = [
        Validators.required,
        customCCTValidator(
          this.flextuneFormGroup,
          checkProp,
          condition,
          min,
          max,
          this.propertiesInfo[checkProp].min,
          this.propertiesInfo[checkProp].max
        ),
      ];
    }
    control.setValidators(validators);
  }

  syncFormControlWithPropInfoValue(propName) {
    const control = this.flextuneFormGroup.controls[propName];
    if (this.valueChangesSubs[propName]) return;
    this.valueChangesSubs[propName] = control.valueChanges.subscribe(
      (value) => {
        this.propertiesInfo[propName].value = value;
        if (propName == "CoolCct" || propName == "WarmCct") {
          const checkProp = propName == "CoolCct" ? "WarmCct" : "CoolCct";
          const checkControl = this.flextuneFormGroup.get(checkProp);
          this.updateOnlyValidity(checkProp);
          if (control.valid && checkControl.valid && this.isIndoor)
            this.onCCTValueChange();
        }
        if (control.valid) {
          if (propName == "CctCoolest" || propName == "CctWarmest") {
            this.onCctWarmestOrCoolestChange(propName);
          }
          if (
            [
              "CoolRatedLm",
              "WarmRatedLm",
              "CoolRatedCurrent",
              "WarmRatedCurrent",
            ].includes(propName)
          ) {
            this.updateConstraintsAndLmForAOC();
          }

          if (propName == "CoolAOC" || propName == "WarmAOC") {
            this.updateConstraintsAndLmForAOC(true);
          }
        }
        if (propName == "LightOutputOverCct") {
          this.onLightOutputChange();
        }
      }
    );
  }

  updateConstraintsAndLmForAOC(updateOnlyLumen = false) {
    const isConstant =
      this.propertiesInfo["LightOutputOverCct"].value != "Flexible";
    const coolratedLm =
      this.propertiesInfo["CoolRatedLm"].value > 0 || !isConstant
        ? this.propertiesInfo["CoolRatedLm"].value
        : 1;
    const coolratedCurrent =
      this.propertiesInfo["CoolRatedCurrent"].value > 0 || !isConstant
        ? this.propertiesInfo["CoolRatedCurrent"].value
        : 1;
    const warmratedLm =
      this.propertiesInfo["WarmRatedLm"].value > 0 || !isConstant
        ? this.propertiesInfo["WarmRatedLm"].value
        : 1;
    const warmratedCurrent =
      this.propertiesInfo["WarmRatedCurrent"].value > 0 || !isConstant
        ? this.propertiesInfo["WarmRatedCurrent"].value
        : 1;

    const getMax = (type, driverMax) => {
      return type == "CoolAOC"
        ? Math.min(
            Math.round(
              (driverMax * warmratedLm * coolratedCurrent) /
                (warmratedCurrent * coolratedLm)
            ),
            driverMax
          )
        : Math.min(
            Math.round(
              (driverMax * coolratedLm * warmratedCurrent) /
                (coolratedCurrent * warmratedLm)
            ),
            driverMax
          );
    };

    const getMin = (type, driverMin) => {
      return type == "CoolAOC"
        ? Math.max(
            Math.round(
              (driverMin * warmratedLm * coolratedCurrent) /
                (warmratedCurrent * coolratedLm)
            ),
            driverMin
          )
        : Math.max(
            Math.round(
              (driverMin * coolratedLm * warmratedCurrent) /
                (coolratedCurrent * warmratedLm)
            ),
            driverMin
          );
    };

    const updatePropInfo = (propName) => {
      const propData = this.propertiesInfo[propName];
      const schemaData = this.properties[propName];
      let min = isConstant
        ? getMin(propName, this.properties[propName].minimum)
        : this.properties[propName].minimum;
      let max = isConstant
        ? getMax(propName, this.properties[propName].maximum)
        : this.properties[propName].maximum;
      if (min < schemaData.minimum) min = schemaData.minimum;
      if (min > schemaData.maximum) min = schemaData.maximum;
      if (max < min) max = min;
      if (max > schemaData.maximum) max = propData.maximum;
      propData["min"] = min;
      propData["max"] = max;
      propData["options"] = {
        floor: propData["sliderMin"],
        ceil: propData["sliderMax"],
        minLimit: min,
        maxLimit: max,
      };
    };
    const updateAOCFormValue = (propName) => {
      const propData = this.propertiesInfo[propName];
      const value = this.flextuneFormGroup.get(propName).value;
      if (!isConstant || propName == "CoolAOC") {
        propData.value = value;
        if (value < propData.min) propData.value = propData.min;
        if (value > propData.max) propData.value = propData.max;
      } else {
        const ratedLm = this.flextuneFormGroup.get("warmLightRatedLumen").value;
        propData.value = Math.round((ratedLm * warmratedCurrent) / warmratedLm);
      }
      let obj = {};
      obj[propName] = propData.value;
      this.flextuneFormGroup.patchValue({ ...obj }, { emitEvent: false });
      this.updateOnlyValidity(propName);
    };

    const updateratedLm = (propName, ratedlmProp) => {
      const propData = this.propertiesInfo[propName];
      let lm = 0;
      if (isConstant) {
        lm = Math.round(
          (this.propertiesInfo["CoolAOC"].value * coolratedLm) /
            coolratedCurrent
        );
      } else {
        if (propName == "CoolAOC") {
          lm =
            coolratedLm > 0 && coolratedCurrent > 0
              ? Math.round((propData.value * coolratedLm) / coolratedCurrent)
              : 0;
        } else {
          lm =
            warmratedCurrent > 0 && warmratedLm > 0
              ? Math.round((propData.value * warmratedLm) / warmratedCurrent)
              : 0;
        }
        if (lm > 10000) lm = 10000;
      }
      let obj = {};
      obj[ratedlmProp] = lm;
      this.flextuneFormGroup.patchValue({ ...obj }, { emitEvent: false });
    };

    if (!updateOnlyLumen) {
      updatePropInfo("CoolAOC");
      updatePropInfo("WarmAOC");
      updateAOCFormValue("CoolAOC");
    }
    updateratedLm("CoolAOC", "coolLightRatedLumen");
    if (!isConstant && !updateOnlyLumen) {
      updateAOCFormValue("WarmAOC");
    }
    updateratedLm("WarmAOC", "warmLightRatedLumen");
    if (isConstant) {
      updateAOCFormValue("WarmAOC");
    }
    this.saveValueOnChange();
  }

  onLightOutputChange(checkOnlyDisable = false) {
    const value = this.propertiesInfo["LightOutputOverCct"].value;
    const warmAOCControl = this.flextuneFormGroup.get("WarmAOC");
    if (value == "Flexible") {
      warmAOCControl.enable({ emitEvent: false });
    } else {
      warmAOCControl.disable({ emitEvent: false });
    }
    if (!checkOnlyDisable) this.updateConstraintsAndLmForAOC();
  }

  onCCTValueChange() {
    let cctCoolestValue = this.flextuneFormGroup.get("CctCoolest").value;
    let cctWarmestValue = this.flextuneFormGroup.get("CctWarmest").value;
    let coolCctValue = this.flextuneFormGroup.get("CoolCct").value;
    let warmCctValue = this.flextuneFormGroup.get("WarmCct").value;
    if (cctCoolestValue != null) {
      if (
        cctCoolestValue > coolCctValue &&
        this.flextuneFormGroup.get("CoolCct").valid
      )
        cctCoolestValue = coolCctValue;
      if (
        cctCoolestValue < warmCctValue &&
        this.flextuneFormGroup.get("WarmCct").valid
      )
        cctCoolestValue = warmCctValue;
      this.preparePropValues("CctCoolest", cctCoolestValue);
    }
    if (cctWarmestValue != null) {
      if (
        cctWarmestValue < warmCctValue &&
        this.flextuneFormGroup.get("WarmCct").valid
      )
        cctWarmestValue = warmCctValue;
      if (
        cctWarmestValue > coolCctValue &&
        this.flextuneFormGroup.get("CoolCct").valid
      )
        cctWarmestValue = coolCctValue;
      this.preparePropValues("CctWarmest", cctWarmestValue);
    }
    this.preparePropertyConstraints("CctCoolest");
    this.preparePropertyConstraints("CctWarmest");
    this.flextuneFormGroup.patchValue(
      {
        CctCoolest: cctCoolestValue,
        CctWarmest: cctWarmestValue,
      },
      { emitEvent: false }
    );
    this.updateOnlyValidity("CctCoolest");
    this.updateOnlyValidity("CctWarmest");
    this.onCctWarmestOrCoolestChange("");
  }

  onCctWarmestOrCoolestChange(propName) {
    if (propName == "CctCoolest") {
      this.preparePropertyConstraints("CctWarmest");
    } else if (propName == "CctWarmest") {
      this.preparePropertyConstraints("CctCoolest");
    }
    const cctCoolest = this.flextuneFormGroup.get("CctCoolest");
    const cctWarmest = this.flextuneFormGroup.get("CctWarmest");
    let cctCoolestValue = cctCoolest.value;
    let cctWarmestValue = cctWarmest.value;
    let powerValue = this.flextuneFormGroup.get("PowerOnCct").value;
    let systemFailureValue =
      this.flextuneFormGroup.get("SystemFailureCct").value;
    if (cctWarmest.valid) {
      if (powerValue < cctWarmestValue && powerValue != null)
        powerValue = cctWarmestValue;
      if (systemFailureValue < cctWarmestValue && systemFailureValue != null)
        systemFailureValue = cctWarmestValue;
    }
    if (cctCoolest.valid) {
      if (powerValue > cctCoolestValue && powerValue != null)
        powerValue = cctCoolestValue;
      if (systemFailureValue > cctCoolestValue && systemFailureValue != null)
        systemFailureValue = cctCoolestValue;
    }
    this.preparePropValues("PowerOnCct", powerValue);
    this.preparePropValues("SystemFailureCct", systemFailureValue);
    this.preparePropertyConstraints("PowerOnCct");
    this.preparePropertyConstraints("SystemFailureCct");
    this.flextuneFormGroup.patchValue({
      PowerOnCct: powerValue,
      SystemFailureCct: systemFailureValue,
    });
  }
  async onSliderValueChange(value, property) {
    let formvalue = {};
    formvalue[property] = value;
    this.flextuneFormGroup.patchValue({
      ...formvalue,
    });
  }

  setValueInLocal() {
    let value = {};
    for (let key in this.flextuneFormGroup.controls) {
      let data = this.propertiesInfo[key]
        ? { ...this.propertiesInfo[key] }
        : {};
      data["value"] = this.flextuneFormGroup.get(key).value;
      value[key] = data;
    }
    let data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      data["resp"]["flextuneObj"] = value;
    } else {
      data["resp"] = { flextuneObj: value };
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }

  getValueFromLocal() {
    let data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      if (data["resp"]["flextuneObj"]) return data["resp"]["flextuneObj"];
      if (data["resp"]["FlexTune"]) {
        let localData = data["resp"]["FlexTune"];
        localData["fromDevice"] = true;
        return localData;
      }
    }
    return null;
  }

  buildForm() {
    let localData = this.getValueFromLocal();
    let formData = {};
    let propConstraints = {};
    if (!localData) {
      this.applicableProperties.forEach((prop) => {
        const propData = this.properties[prop];
        formData[prop] = propData.default;
      });
    } else {
      if (localData["fromDevice"]) {
        formData = localData;
      } else {
        for (let key in this.flextuneFormGroup.controls) {
          formData[key] = localData[key].value;
          let constraints = localData[key];
          constraints["hasValueFromLocal"] = true;
          propConstraints[key] = constraints;
        }
      }
    }
    this.applicableProperties.forEach((prop) => {
      this.preparePropValues(prop, formData[prop]);
      this.syncFormControlWithPropInfoValue(prop);
    });
    this.applicableProperties.forEach((prop) => {
      this.preparePropertyConstraints(
        prop,
        propConstraints[prop] ? propConstraints[prop] : {}
      );
    });
    this.applicableProperties.forEach((prop) => {
      this.addValidatorForProp(prop);
    });
    this.flextuneFormGroup.patchValue({ ...formData }, { emitEvent: false });
    this.applicableProperties.forEach((prop) => {
      this.updateOnlyValidity(prop);
    });
    if (!localData || localData["fromDevice"]) {
      this.onLightOutputChange();
      if (this.isIndoor) this.onCCTValueChange();
    } else {
      this.onLightOutputChange(true);
    }
    this.saveValueOnChange();
  }

  restoreDefault() {
    let data = {};
    this.applicableProperties.forEach((prop) => {
      const propData = this.propertiesInfo[prop];
      if (propData) {
        data[prop] = propData.default;
        propData["value"] = propData.default;
      }
    });
    this.flextuneFormGroup.patchValue(
      {
        ...data,
      },
      { emitEvent: false }
    );
    this.applicableProperties.forEach((prop) => {
      this.updateOnlyValidity(prop);
    });
    this.onLightOutputChange();
    if (this.isIndoor) this.onCCTValueChange();
    this.saveValueOnChange();
  }

  updateOnlyValidity(controlName) {
    const control = this.flextuneFormGroup.get(controlName);
    const isdisabled = control.disabled;
    if (isdisabled) control.enable({ emitEvent: false });
    if (this.valueChangesSubs[controlName]) {
      this.valueChangesSubs[controlName].unsubscribe();
      delete this.valueChangesSubs[controlName];
      control.updateValueAndValidity();
      this.syncFormControlWithPropInfoValue(controlName);
    } else {
      control.updateValueAndValidity();
    }
    if (isdisabled) control.disable({ emitEvent: false });
  }

  checkDefault() {
    return !this.applicableProperties.some((prop) => {
      const propData = this.propertiesInfo[prop];
      const control = this.flextuneFormGroup.controls[prop];
      return control.value != propData.default;
    });
  }

  sendFlextuneData() {
    const data = {};
    this.applicableProperties.forEach((prop) => {
      const propData = this.propertiesInfo[prop];
      if (propData) data[prop] = propData.value;
    });
    this.service.sendFlextuneData(data);
  }

  isControlEnabled(controlName) {
    return this.flextuneFormGroup.get(controlName).enabled;
  }
}
