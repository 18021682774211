import { Directive, HostBinding, Output, HostListener, EventEmitter, ElementRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[apInputError]'
})
export class appInputError {

  @Input() formControl: FormControl;
  @Input() validOnDisbale = false;
  constructor(public element: ElementRef) {
  }

  ngAfterViewInit(){
    if(this.formControl){
      this.formControl.statusChanges.subscribe(status => {
        this.handleStatus(status);
      });
      const status = this.formControl.status;
      this.handleStatus(status);
    }
  }

  handleStatus(status){
    if(status == 'INVALID'){
      this.element.nativeElement.classList.add('hasError');
    }
    else if(status == 'DISABLED'){
      this.formControl.enable({onlySelf: true});
      this.formControl.updateValueAndValidity({onlySelf: true});
      this.formControl.disable({onlySelf: true, emitEvent: false});
      if(this.validOnDisbale) this.element.nativeElement.classList.remove('hasError');
    }
   else{
      this.element.nativeElement.classList.remove('hasError');
    }
  }

}
