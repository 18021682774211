import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from "@angular/forms";
import { ApiService } from "src/app/core-module/services/api.service";
import { FieldType } from "@ngx-formly/core";
import { Subscription } from "rxjs";

export const CustomPasswordValidator = (form: FormGroup, checkprop: string) => {
  return (ctrl: FormControl) => {
    const checkControl = form.get(checkprop);
    let props = ["p1", "p2", "p3", "p4", "vp1", "vp2", "vp3", "vp4"];
    let hasError = ctrl.value != checkControl.value 
      || !ctrl.value 
      || !(Number(ctrl.value) >=0 && Number(ctrl.value) <= 255)
      || props.every(prop => form.get(prop).value == "0")
      || props.every(prop => form.get(prop).value == "255")
     
    if (hasError) return { error: true };
    return null;
  };
};
@Component({
  selector: "app-owp",
  templateUrl: "./owp.component.html",
  styleUrls: ["./owp.component.scss"],
})
export class OwpComponent extends FieldType implements OnInit {
  isSelectAll: boolean;
  @Input() field;

  maskWebNames: any = {
    DALI102ADDRESSING: "DALI 102 Addressing",
    ALO: "ALO",
    ALOMIN: "ALO Min",
    FLEXTUNE: "FlexTune",
    AOC: "AOC",
    CLO: "CLO",
    CLOLITE: "CLOLite",
    CORRIDORMODE: "Corridor Mode",
    CORRIDOR: "Corridor",
    TOUCHDIM: "Touch and Dim",
    DTL: "DTL",
    DYNADIMMER: "Dynadimmer",
    LINESWITCH: "Line Switch",
    TOUCHANDDIM: "Touch and Dim",
    DIMMINGINTERFACE: "Dimming Interface",
    LUMINAIREINFO: "Luminaire Info",
    LUMINFOFORMAT3: "Luminaire Info",
    LUMINFOFORMAT: "Luminaire Info",
    OWP: "OWP",
    DALIPSU: "DALI PSU",
    DCEMERGENCY: "DC Emergency",
    ZTV: "ZTV",
    CODEDMAINS: "Coded Mains",
    MINDIMLEVEL: "Min Dim Level",
    MTP: "MTP",
    AST: "AST",
    AMPDIM: "Amp Dim",
    AUXOUTPUTVOLTAGE: "Aux Output Voltage",
    STEPDIMMING: "Step Dimming",
    LSI: "LSI",
    LSA: "LSA",
    CODEDLIGHT: "Coded Light",
    EOL:"EOL"
  };

  pathVal;
  selectedFeatures: any = {};
  owpAvailableFeatures = [];
  owpProperties = {};
  owpPasswordForm = new FormGroup({});
  valueChangesSub: {[key: string]:Subscription}= {};

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    this.apiService.getowpRestoreDefaultValue.subscribe((response) => {
      if (response) {
        this.restoreDefault();
      }
    });

    if (this.field) {
      this.owpProperties = this.field.properties;
      this.buildInitialData();
    }
  }

  buildInitialData() {
    let localData = this.getValueFromLocal();
    if(localData && localData["OwpEnabled"]) localData["OwpEnabled"] = localData["OwpEnabled"].map(feat => feat.toUpperCase().trim())
    this.owpProperties["OwpEnabled"].featureSet.forEach((feature: string) => {
      let name = feature.toUpperCase().trim();
      if (name !== "SRPSU") {
        this.owpAvailableFeatures.push(name);
        this.selectedFeatures[name] =
          localData && localData["OwpEnabled"]
            ? localData["OwpEnabled"].includes(name)
            : false;
      }
    });
    let keys_relations = {"p1":"vp1", "p2":"vp2", "p3":"vp3", "p4":"vp4", "vp1":"p1", "vp2":"p2", "vp3":"p3", "vp4":"p4"};
    for (let item in keys_relations) {
      const control = new FormControl(null);
      this.owpPasswordForm.addControl(item, control);
      this.syncFormChanges(item);
    }
    for (let name in this.owpPasswordForm.controls) {
      this.owpPasswordForm
        .get(name)
        .setValidators(CustomPasswordValidator(this.owpPasswordForm, keys_relations[name]));
    }
    let passwordValue={};
    if(localData){
      for(let i=0; i<4; i++){
        passwordValue['p'+(i+1)] = localData['OwpPassword'] && localData['OwpPassword'][i] ? localData['OwpPassword'][i] : null;
        passwordValue['vp'+(i+1)] = localData['OwpVerifyPassword'] && localData['OwpVerifyPassword'][i] ? localData['OwpVerifyPassword'][i] : null
      }
      this.owpPasswordForm.patchValue({...passwordValue}, {emitEvent: false});
    }
    this.checkForSelectAll();
    this.checkForPasswordEnable();
    this.saveValueOnChange();
  }

  restoreDefault(){
    this.owpAvailableFeatures.forEach(feat => {
      this.selectedFeatures[feat] = false;
    })
    this.isSelectAll = false;
    this.checkForPasswordEnable();
    this.saveValueOnChange();
  }

  syncFormChanges(item) {
    const control = this.owpPasswordForm.get(item);
    this.valueChangesSub[item] = control.valueChanges.subscribe((res) => {
      for (let key in this.owpPasswordForm.controls) {
        this.updateonlyValidity(key);
      }
      this.saveValueOnChange();
    });
  }

  onCheckboxChange(item) {
    this.checkForSelectAll();
    this.checkForPasswordEnable();
    this.saveValueOnChange();
  }

  selectAll(value) {
    this.isSelectAll = value;
    this.owpAvailableFeatures.forEach((feat) => {
      this.selectedFeatures[feat] = value;
    });
    this.checkForPasswordEnable();
    this.saveValueOnChange();
  }

  checkForSelectAll() {
    this.isSelectAll = this.owpAvailableFeatures.every(
      (feat) => this.selectedFeatures[feat]
    );
  }

  checkForPasswordEnable() {
    let enable = this.owpAvailableFeatures.some(
      (feat) => this.selectedFeatures[feat]
    );
    for (let key in this.owpPasswordForm.controls) {
      const control = this.owpPasswordForm.get(key);
      if (enable) control.enable({ emitEvent: false });
      else {
        control.patchValue(null, { emitEvent: false });
        control.disable({ emitEvent: false });
      }
      this.updateonlyValidity(key);
    }
  }

  getRoundOff(number) {
    return Math.round(number);
  }

  updateonlyValidity(controlName) {
    const control = this.owpPasswordForm.get(controlName);
    if (this.valueChangesSub[controlName]) {
      this.valueChangesSub[controlName].unsubscribe();
      delete this.valueChangesSub[controlName];
      control.updateValueAndValidity();
      this.syncFormChanges(controlName);
    } else {
      control.updateValueAndValidity();
    }
  }

  saveValueOnChange(){
    this.apiService.setowpDefaultColor(this.checkForDefault());
    this.sendOwpData();
    this.apiService.owpInputError(!this.checkForDefault() && this.owpPasswordForm.status == 'INVALID');
  }

  checkForDefault(){
    return !this.owpAvailableFeatures.some(feat => this.selectedFeatures[feat])
  }

  sendOwpData(){
    let value = {};
    value['OwpPassword'] = [];
    value['OwpVerifyPassword'] = [];
    value['OwpEnabled'] = this.owpAvailableFeatures.filter(feat => this.selectedFeatures[feat]);
    if(value['OwpEnabled'].length > 0){
      for(let key of ['p1', 'p2', 'p3', 'p4']){
        value['OwpPassword'].push(this.owpPasswordForm.get(key).value);
      }
      for(let key of ['vp1', 'vp2', 'vp3', 'vp4']){
        value['OwpVerifyPassword'].push(this.owpPasswordForm.get(key).value)
      }
    }
    this.setValueInLocal(value);
    this.apiService.sendowpdata({
      OwpEnabled : value['OwpEnabled'],
      OwpPassword: value['OwpPassword'].map(val => val ? Number(val):val)
    });
  }

  getValueFromLocal() {
    let data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && data["resp"]) {
      if (data["resp"]["OWP"]) return data["resp"]["OWP"];
    }
    return null;
  }

  setValueInLocal(value){
    let data = JSON.parse(localStorage.getItem("configurationData"));
    if(data && data['resp']) data['resp']['OWP'] = value;
    else data['resp'] = {'OWP': value}
    localStorage.setItem("configurationData", JSON.stringify(data));
  }
}
