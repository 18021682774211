import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { element } from "protractor";
import { takeUntil } from "rxjs/operators";
import { Subject, Subscription, forkJoin, timer, interval } from "rxjs";
import { ReadDefaultValuesPopupComponent } from "../read-default-values-popup/read-default-values-popup.component";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { HttpHeaders } from "@angular/common/http";
//import { setTimeout } from 'timers';
import { async, Promise } from "q";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-supported-features-multi",
  templateUrl: "./supported-features.component-multi.html",
  styleUrls: ["./supported-features.component-multi.scss"],
})
export class SupportedFeaturesComponentMulti implements OnInit, OnDestroy {
  @Input() public retryClicked;
  @Input() public initialCount:any=0;
  @Input() public outOfCount=24;
  @Input() public isFailed: boolean = false;
  retryImg: boolean = false;
  allFeatures = [];
  hidePrevFeat: boolean = false;
  greenFeat: any = [];
  redFeat: any = [];
  combineAllFeat: any = [];
  corruptIndex = 0;
  nonCorruptIndex = 0;
  redFeata: any = [];
  nonRedCorruptIndex = 0;
  finalIndex = 0;
  FinalArray = [];
  unsubscribe = new Subject<any>();
  envScehmaLIstApi: any;
  countRedGreen: any = 0;
  subscription: Subscription;
  deviceInfo: any;
  startConfigurationData: any;
  allFeaturesOFDevice = {};
  softwareVersionSchema: any;
  invertedImg: boolean = false;
  greenArray = [];
  redArray = [];
  orangeArray = [];
  regions: string[];
  searchName: any[];
  apiList: any = [];
  fullDeviceList = "search/api/v1/search/v1/devices";
  envDeviceListURL: string;
  url: any;
  customerLoader;
  contniueBtnClicked: boolean = false;
  continueShowBtnStatus: boolean = false;
  pathVal:string;
  availableFeatureSub:Subscription;
  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    public route: Router,
    public activemodal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    this.apiService.getAvailableFeatureMulti.subscribe((data: any) => {
      if (!isNullOrUndefined(data) && data.length > 0) {
        data.forEach((element) => {
          this.hidePrevFeat = true;
          let val = element.toString();
          if (val.match(/[^,]*alo[^,]*/g)) {
            this.allFeatures.push("ALO");
          }
          if (val.match(/[^,]*aoc[^,]*/g)) {
            this.allFeatures.push("AOC");
          }
          if (val.match(/[^,]*clo[^,]*/g) || val.match(/[^,]*clolite[^,]*/g)) {
            this.allFeatures.push("CLO");
          }
          if (val.match(/[^,]*ast[^,]*/g)) {
            this.allFeatures.push("AST");
          }
          if (val.match(/[^,]*luminfoformat[^,]*/g)) {
            this.allFeatures.push("Luminaire Info");
          }
          if (val.match(/[^,]*srpsu[^,]*/g)) {
            this.allFeatures.push("DALI PSU");
          }
          if (val.match(/[^,]*ztv[^,]*/g)) {
            this.allFeatures.push("ZTV");
          }
          if(val.match(/[^,]*dynadimmer[^,]*/g)){
          this.allFeatures.push('Dynadimmer');
          }
          if(val.match(/[^,]*lineswitch[^,]*/g)){
          this.allFeatures.push('Line Switch');
          }
          // if(val.match(/[^,]*mtp[^,]*/g)){
          //   this.allFeatures.push('Mtp');
          // }
          if(val.match(/[^,]*ampdim[^,]*/g)){
          this.allFeatures.push('Amp Dim');
          }
          if (val.match(/[^,]*dcemergency[^,]*/g)) {
            this.allFeatures.push("DC Emergency");
          }
          if (val.match(/[^,]*dtl-[^,]*/g)) {
            this.allFeatures.push("DTL");
          }
          if (val.match(/[^,]*mtp-[^,]*/g)) {
            this.allFeatures.push("MTP");
          }
          if (val.match(/[^,]*mindimlevel-[^,]*/g)) {
            this.allFeatures.push("Min Dim Level");
          }
          if (val.match(/[^,]*touchdim-[^,]*/g)) {
            this.allFeatures.push("Touch Dim");
          }
          if (val.match(/[^,]*corridor-[^,]*/g)) {
            this.allFeatures.push("Corridor");
          }
          if(val.match(/[^,]*codedmains-[^,]*/g)){
          this.allFeatures.push('Coded Mains');
          }
          if (val.match(/[^,]*diia253maintenance-[^,]*/g)) {
            this.allFeatures.push('DALI 253M');
          }
          // Below features removed from list of DC

          // if(val.match(/[^,]*corridor-[^,]*/g)){
          //   this.allFeatures.push('Corridor');
          // }
          // if(val.match(/[^,]*dcemergency-[^,]*/g)){
          //   this.allFeatures.push('DC Emergency');
          // }
          // if(val.match(/[^,]*touchdim-[^,]*/g)){
          //   this.allFeatures.push('Touch and Dim');
          // }
          // if(val.match(/[^,]*dtl[^,]*/g)){
          //   this.allFeatures.push('DTL');
          // }
        });
        this.allFeatures = this.allFeatures.sort();
        let writingDevice = JSON.parse(localStorage.getItem("isWriting"));
        if (writingDevice) {
          this.invertedImg = true;
        } else {
          this.invertedImg = false;
        }
        this.allFeaturesOFDevice = {
          selectedFeature: this.allFeatures,
          allFeature: data,
        };
        localStorage.setItem(
          "supporteFeatures",
          JSON.stringify(this.allFeaturesOFDevice)
        );
      } else {
        this.allFeatures = [];
      }
    });

    this.apiService.nonCorrupt.subscribe((data: any) => {
      if (data) {
      }
    });
    this.apiService.corruptFeat.subscribe((data: any) => {
      if (data) {
      }
    });
    this.apiService.getRedFeature.subscribe((data: any) => {
      if (data) {
      }
    });
  }

  ngDoCheck() {
    if (JSON.parse(localStorage.getItem("resultFeatures"))) {
      let data = JSON.parse(localStorage.getItem("resultFeatures"));
      if (localStorage.getItem("readerDeviceFullInfo")) {
        this.deviceInfo = JSON.parse(
          localStorage.getItem("readerDeviceFullInfo")
        );
      }
      let greenFeat = [];
      let redFeat = [];
      let orangeFeat = [];

      data["orange"].forEach((element) => {
        if (element) {
          if (element === "LumInfoFormat" || element === "Luminaire info")
            orangeFeat.push("Luminaire Info");
          else if (element === "DCEmergency") orangeFeat.push("DC Emergency");
          else if (element === "AmpDim") orangeFeat.push("Amp Dim");
          else if (element === "LineSwitch") orangeFeat.push("Line Switch");
          else if (element === "MinDimLevel") orangeFeat.push("Min Dim Level");
          else if (element === "CodedMains") orangeFeat.push("Coded Mains");
          else if (element === "TouchDim") orangeFeat.push("Touch Dim");
          else if (element === "Corridor") orangeFeat.push("Corridor");
          else if (element === "DiiA253Maintenance")
            orangeFeat.push("DALI 253M");
          else if (element === "DynaDimmer") orangeFeat.push("Dynadimmer");
          else if (element === "Mtp") orangeFeat.push("MTP");
          else if (element === "DALIPSU") orangeFeat.push("DALI PSU");
          else if (element === "ZtvOutput")
            orangeFeat.push("ZTV Output");
          else orangeFeat.push(element);
        }
      });
      data["green"].forEach((element) => {
        if (element) {
          if (element[0] === "LumInfoFormat" || element === "Luminaire info")
            greenFeat.push("Luminaire Info");
          else if (element[0] === "DCEmergency") greenFeat.push("DC Emergency");
          else if (element[0] === "AmpDim") greenFeat.push("Amp Dim");
          else if (element[0] === "LineSwitch") greenFeat.push("Line Switch");
          else if (element[0] === "MinDimLevel")
            greenFeat.push("Min Dim Level");
          else if (element[0] === "CodedMains") greenFeat.push("Coded Mains");
          else if (element[0] === "TouchDim") greenFeat.push("Touch Dim");
          else if (element[0] === "Corridor") greenFeat.push("Corridor");
          else if (element[0] === "DiiA253Maintenance")
            greenFeat.push("DALI 253M");
          else if (element[0] === "DynaDimmer") greenFeat.push("Dynadimmer");
          else if (element[0] === "Mtp") greenFeat.push("MTP");
          else if (element[0] === "DALIPSU") greenFeat.push("DALI PSU");
          else if (element[0] === "ZtvOutput")greenFeat.push("ZTV Output");
          else greenFeat.push(element[0]);
        }
      });
      greenFeat = greenFeat.reduce((unique, o) => {
        if (!unique.some((obj) => obj === o)) {
          unique.push(o);
        }
        return unique;
      }, []);
      data["red"].forEach((element) => {
        if (element) {
          for (let i = 0; i < element.length; i++) {
            if (element[i].featureName === "LumInfoFormat")
              redFeat.push("Luminaire Info");
            else if (element[i].featureName === "DCEmergency")
              redFeat.push("DC Emergency");
            else if (element[i].featureName === "AmpDim")
              redFeat.push("Amp Dim");
            else if (element[i].featureName === "LineSwitch")
              redFeat.push("Line Switch");
            else if (element[i].featureName === "MinDimLevel")
              redFeat.push("Min Dim Level");
            else if (element[i].featureName === "CodedMains")
              redFeat.push("Coded Mains");
            else if (element[i].featureName === "TouchDim")
              redFeat.push("Touch Dim");
            else if (element[i].featureName === "Corridor")
              redFeat.push("Corridor");
            else if (element[i].featureName === "DiiA253Maintenance")
              redFeat.push("DALI 253M");
            else redFeat.push(element[i].featureName);
          }
        }
      });
      redFeat = redFeat.reduce((unique, o) => {
        if (!unique.some((obj) => obj === o)) {
          unique.push(o);
        }
        return unique;
      }, []);
      localStorage.removeItem("resultFeatures");
      this.greenArray = greenFeat;
      this.redArray = redFeat;
      this.orangeArray = orangeFeat;

      this.concatFeat();
    }
  }
  concatFeat() {
    this.customerLoader = true;
    this.hidePrevFeat = false;
    this.retryImg = true;
    let green = [];
    let red = [];
    let orange = [];
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.url = url;
        }
      });
    // special condtion only for cloLite
    this.greenArray = this.apiService.renameFeatures(this.greenArray);
    let commonOrange = this.greenArray.filter((item) =>
      this.orangeArray.includes(item)
    );
    commonOrange.forEach((element) => {
      // AOC, CLO
      if (element) {
        orange.push({ name: element, flag: "orange" });
      }
    });
    for (let i = 0; i < this.greenArray.length; i++) {
      for (let j = 0; j < orange.length; j++) {
        if (this.greenArray[i] === orange[j]["name"]) {
          this.greenArray.splice(i, 1);
        }
      }
    }
    this.greenArray.forEach((element) => {
      // CLO
      if (element) {
        green.push({ name: element, flag: "green" });
      }
    });
    // Failed Feature Array
    this.redArray = this.apiService.renameFeatures(this.redArray);
    this.redArray.forEach((element) => {
      // ALO
      if (element) {
        red.push({ name: element, flag: "red" });
      }
    });
    this.FinalArray = green.concat(orange).concat(red);
    let allFeat = JSON.parse(localStorage.getItem("supporteFeatures"));
    let filterRedFeatures = allFeat["selectedFeature"].filter(
      (item) => this.greenArray.indexOf(item) < 0
    );
    if (this.FinalArray.length > 0) {
      this.sort(this.FinalArray);
    }
    setInterval(() => {
      if (this.finalIndex == this.FinalArray.length) {
        let config = JSON.parse(
          localStorage.getItem("readerConfigurationData")
        );
        this.finalIndex++;
        if (this.deviceInfo) {
          this.getReaderValue(config);
        } else {
          this.searchWith12Nc(config);
        }

        const obsDelay = interval(10000);
        obsDelay.subscribe((d) => {});

        return;
      } else if (this.finalIndex < this.FinalArray.length) {
        this.combineAllFeat.push(this.FinalArray[this.finalIndex]);
        this.finalIndex++;
      }
    }, 200);
  }

  getReaderValue(config) {
    this.apiService.getEnvValue();
    this.subscription = this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.unsubscribe.next();
          this.envScehmaLIstApi = url;
          this.apiService
            .getConfigurationAPI(
              this.envScehmaLIstApi,
              config["configurationID"]
            )
            .subscribe(
              (resp: any) => {
                let readerInfo = JSON.parse(
                  localStorage.getItem("setReaderDEviceInfo")
                );
                let features = JSON.parse(
                  localStorage.getItem("supporteFeatures")
                );
                let swSchema = JSON.parse(
                  localStorage.getItem("softwareSchema")
                );
                let config = JSON.parse(
                  localStorage.getItem("readerConfigurationData")
                );
                const index = features["selectedFeature"].indexOf("ALO");
                if (index > -1) {
                  features["selectedFeature"].splice(index, 1);
                  features["selectedFeature"].push("Alo");
                }
                const index1 = features["selectedFeature"].indexOf("AOC");
                if (index1 > -1) {
                  features["selectedFeature"].splice(index1, 1);
                  features["selectedFeature"].push("Aoc");
                }
                const index2 = features["selectedFeature"].indexOf("CLO");
                if (index2 > -1) {
                  features["selectedFeature"].splice(index2, 1);
                  features["selectedFeature"].push("Clo");
                }
                let readValueObj = {};
                if (localStorage.getItem("saveReadValue")) {
                  readValueObj = JSON.parse(
                    localStorage.getItem("saveReadValue")
                  );
                  let allReadValObj = { ...readValueObj, ...resp };
                  for (let i in allReadValObj) {
                    for (let j in resp) {
                      if (i === j) {
                        delete readValueObj[i];
                        readValueObj[i] = resp[j];
                      }
                    }
                  }
                  resp = readValueObj;
                }
                localStorage.setItem("saveReadValue", JSON.stringify(resp));

                if (
                  resp.CLOLite &&
                  localStorage.getItem("readingDevice") === "true"
                ) {
                  this.apiService.sendLitePower(
                    resp.CLOLite.CloLiteInitialPowerLevel
                  );
                  this.apiService.sendliteHour(
                    resp.CLOLite.CloLiteMaximumWorkingHours
                  );
                  this.apiService.setEnableGraph(resp.CLOLite.CloLiteEnabled);
                }

                if (
                  resp.CLO
                ) {
                  this.apiService.setEnableGraph(resp.CLO.CloEnabled);
                }
                this.startConfigurationData = {
                  configurationID: config["configurationID"],
                  deviceName: config["deviceName"],
                  device12nc: config["device12nc"],
                  softwareVersion: readerInfo.deviceMetadata.deviceSwVersion,
                  feature12Nc: config["feature12Nc"]
                    ? config["feature12Nc"]
                    : features["allFeature"],
                  selectedFeaturesInfo: features["allFeature"],
                  resp: resp,
                  picture:
                    "https://www.api.easydesignintool.signify.com" +
                    this.deviceInfo[0].devicetypePicture,
                  softwareVerSchema: swSchema["swschema"],
                  luminSoftVer: swSchema["luminSchemaVer"],
                  dtlSoftVer: swSchema["dtlSchemaVer"],
                  flextuneSoftVer: swSchema["flextuneSoftVer"],
                  mindimlevelSoftVer: !isNullOrUndefined(swSchema["mindimlevelSchemaVer"])?swSchema["mindimlevelSchemaVer"]:swSchema["mindimlevelSoftVer"],
                  dalipsuSoftVer: swSchema["dalipsuSchemaVer"],
                  mtpSoftVer: swSchema["mtpSoftVer"],
                  dimminginterfaceSoftVer: swSchema["dimminginterfaceSoftVer"],
                  cloSoftVer: swSchema["cloSoftVer"],
                  multiconfigSoftVer: swSchema["multiconfigSoftVer"],
                  owpSoftVer: swSchema["owpSoftVer"],
                  aloSoftVer: swSchema["aloSoftVer"],
                  aocSoftVer: swSchema["aocSoftVer"],
                  touchdimSoftVer: swSchema["touchdimSoftVer"],
                  diia253maintenanceSoftVer:
                    swSchema["diia253maintenanceSoftVer"],
                  corridorSoftVer: swSchema["corridorSoftVer"],
                  ztvSoftVer: swSchema["ztvSoftVer"],
                  ztvoutputSoftVer: swSchema["ztvoutputSoftVer"],
                  lipSoftVer: swSchema["lipSoftVer"],
                  rsoSoftVer: swSchema["rsoSoftVer"],
                  ampdimSoftVer: swSchema["ampdimSoftVer"],
                  astSoftVer: swSchema["astSoftVer"],
                  cloliteSoftVer: swSchema["cloliteSoftVer"],
                  dynadimmerSoftVer: swSchema["dynadimmerSoftVer"],
                  dcemergencySoftVer: swSchema["dcemergencySoftVer"],
                  lineswitchSoftVer: swSchema["lineswitchSoftVer"],
                  codedmainsSoftVer: swSchema["codedmainsSoftVer"],
                  configurationIDUniqueDC: config["configurationIDUniqueDC"],
                };
                localStorage.setItem(
                  "comparefirmwarenoLatest",
                  JSON.stringify(readerInfo.deviceMetadata.deviceSwVersion)
                );
                localStorage.setItem(
                  "configurationData",
                  JSON.stringify(this.startConfigurationData)
                );
                let writingDevice = JSON.parse(
                  localStorage.getItem("isWriting")
                );
                this.FinalArray.forEach((element) => {
                  if (
                    element["flag"] === "red" ||
                    element["flag"] === "orange"
                  ) {
                    this.countRedGreen++;
                  }
                });
                if (writingDevice) {
                  if (this.countRedGreen > 0) {
                    const modalRef = this.modalService.open(
                      ReadDefaultValuesPopupComponent,
                      {
                        backdrop: "static",
                        keyboard: false,
                        windowClass: "read-device-modal-comp",
                        centered: true,
                        size: "lg",
                      }
                    );
                    modalRef.componentInstance.data = this.FinalArray;
                  } else {
                    this.apiService.sendShowNextSuccessBtnMulti(true);
                    /*    this.apiService.modalClose("close");
                    this.activemodal.close();
                    localStorage.setItem("normalFlow", JSON.stringify(false));

                    this.route.navigate(["/featureLanding"]); */
                  }
                }
                this.customerLoader = false;
                this.finalIndex++;
              },
              (err) => {
                this.customerLoader = false;
              }
            );
        }
      });
  }

  continueBtnClickedOP() {
    if (this.contniueBtnClicked) {
      this.apiService.modalClose("close");
      this.activemodal.close();
      localStorage.setItem("normalFlow", JSON.stringify(false));

      this.route.navigate(["/featureLanding"]);
    }
  }
  sort(items) {
    items.sort(function (a, b) {
      return a.name - b.name || a.name.localeCompare(b.name);
    });
  }

  searchWith12Nc(searchText) {
    let headers: any = new HttpHeaders({});
    let j = 0;
    this.regions = ["EMEA", "NA"];
    this.regions.forEach((element) => {
      headers = { region: element };
      this.searchTextValue(searchText.device12nc, headers, j);
      j++;
    });

    this.unsubscribe.next();
    this.searchName = [];
    let config = JSON.parse(localStorage.getItem("readerConfigurationData"));
    forkJoin(this.apiList).subscribe((resp) => {
      this.deviceInfo = [].concat.apply([], resp);
      localStorage.setItem(
        "readerDeviceFullInfo",
        JSON.stringify(this.deviceInfo)
      );
      this.getReaderValue(config);
    });
  }

  searchTextValue(searchText, headers?, j?) {
    if (this.url.length) {
      this.envDeviceListURL =
        this.url + this.fullDeviceList + "?query=nc12%3A*";
      this.unsubscribe.next();
      this.apiList[j] = this.apiService.apiCall(
        "get",
        this.envDeviceListURL + searchText + "*" + "&showArchive=true",
        "",
        { headers: headers }
      );
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.apiService.sendFeatures("");
    this.apiService.sendCorruptFeat("");
    this.apiService.sendNonCorrupt("");
    if (this.availableFeatureSub) {
      this.availableFeatureSub.unsubscribe();
    }
  }
}
